
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

/** {*/
/*    box-sizing: border-box;*/
/*}*/

/*body {*/
/*    background: #f6f5f7;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    flex-direction: column;*/
/*    font-family: 'Montserrat', sans-serif;*/
/*    height: 100vh;*/
/*    margin: -20px 0 50px;*/
/*}*/

#login-form-container h1 {
    font-weight: bold;
    margin: 0;
}

#login-form-container h2 {
    text-align: center;
}

#login-form-container p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

#login-form-container span {
    font-size: 12px;
}

#login-form-container a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

#login-form-container button {
    border-radius: 20px;
    border: 1px solid #3aa13b;
    background-color: #b1e794;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}


#login-form-container button:disabled {
    background-color: #cccccc;
    border: 1px solid #bbbbbb;
}

#login-form-container button:active {
    transform: scale(0.95);
}

#login-form-container button:focus {
    outline: none;
}

#login-form-container button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
}

#login-form-container form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

#login-form-container input .login-input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

#login-form-container .login-form input {
    background-color: #def1ed;
    border: none;
    padding: 12px 15px;
    margin: 8px 5px;
    width: 80%;
}

#login-form-container .login-form input.fail {
    background-color: #ffd7d5;
    /*border: 1px #ff7875 !important;*/
    padding: 12px 15px;
    margin: 8px 5px;
    width: 80%;
}

#login-form-container .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25),
    0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

#login-form-container .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

#login-form-container .sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

#login-form-container .container.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

#login-form-container .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

#login-form-container .container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

#login-form-container @keyframes show {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}

#login-form-container .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

#login-form-container .container.right-panel-active .overlay-container{
    transform: translateX(-100%);
}

#login-form-container .overlay {
    background: #3aa13b;
    background: -webkit-linear-gradient(to right, #b1e794, #3aa13b);
    background: linear-gradient(to right, #b1e794, #3aa13b);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

#login-form-container .container.right-panel-active .overlay {
    transform: translateX(50%);
}

#login-form-container .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

#login-form-container .overlay-left {
    transform: translateX(-20%);
}

#login-form-container .container.right-panel-active .overlay-left {
    transform: translateX(0);
}

#login-form-container .overlay-right {
    right: 0;
    transform: translateX(0);
}

#login-form-container .container.right-panel-active .overlay-right {
    transform: translateX(20%);
}

#login-form-container .social-container {
    margin: 20px 0;
}

#login-form-container .social-container a {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}

#login-form-container footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

#login-form-container footer p {
    margin: 10px 0;
}

#login-form-container footer i {
    color: red;
}

#login-form-container footer a {
    color: #3c97bf;
    text-decoration: none;
}
